<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row
      dense
      v-if="showCustomRange"
      class="pt-1"
    >
      <v-col
        cols="12"
        md="6"
      >
        <DateAndTimeSelection
          :label="$t('from')"
          filled
          hide-details
          :original-value="initialStart"
          v-model="start"
          @setDateTime="setStart"
          type="from"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <DateAndTimeSelection
          :label="$t('to')"
          filled
          hide-details
          :original-value="initialEnd"
          v-model="end"
          @setDateTime="setEnd"
          type="to"
        />
      </v-col>
      <v-col
        cols="12"
        md="10"
        class="pt-3"
      >
        <v-btn
          block
          large
          depressed
          :color="primaryColor"
          :dark="!colorIsLight && !disabled"
          :disabled="disabled || gettingCustomRange"
          :loading="gettingCustomRange"
          @click="getByTimeRange(start, end)"
        >
          {{ $t('confirm') }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="pt-3"
      >
        <v-btn
          block
          large
          elevation="0"
          :disabled="disabled || gettingCustomRange"
          @click="emitShowCustomRange(false)"
        >
          {{ $t('close') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateAndTimeSelection from "@/components/common/DateAndTimeSelection"

export default {
	name: "CustomDateRange",
	components: {
		DateAndTimeSelection
	},
	props: {
		currentDateInMillis: {
			type: Number,
			default: 0
		},
		fromInMillis: {
			type: Number,
			default: 0
		},
		toInMillis: {
			type: Number,
			default: 0
		},
		disabled: {
			type: Boolean,
		},
		showCustomRange: {
			type: Boolean,
		},
		gettingCustomRange: {
			type: Boolean
		}
	},
	data() {
		return {
			initialStart: null,
			initialEnd: null,
		}
	},
	methods: {
		getByTimeRange(startTimeInMillis, endTimeInMillis) {
			this.$emit('getByTimeRange', { startTimeInMillis, endTimeInMillis })
		},
		emitShowCustomRange(booleanValue) {
			this.$emit('showCustomRange', booleanValue)
			this.start = this.startOfDay
			this.end = this.endOfDay
		},
		setStart(value) {
			this.start = value
		},
		setEnd(value) {
			this.end = value
		}
	},
	computed: {
		colorIsLight() {
			return this.$store.getters.colorIsLight(this.primaryColor)
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		systemTimeInMillis() {
			return this.$store.getters.systemTime * 1
		},
		startOfDay() {
			if(!this.currentDateInMillis) {
				return new Date(this.systemTimeInMillis).setUTCHours(0,0,0,0)
			}
			return new Date(this.currentDateInMillis).setUTCHours(0,0,0,0)
		},
		endOfDay() {
			if(!this.currentDateInMillis) {
				return new Date(this.systemTimeInMillis).setUTCHours(23,59,59,999)
			}
			return new Date(this.currentDateInMillis).setUTCHours(23,59,59,999)
		},
		start: {
			get() {
				if(!this.fromInMillis) {
					return this.startOfDay
				}
				return this.fromInMillis
			},
			set(value) {
				this.$emit('startDateInMillis', value)
			}
		},
		end: {
			get() {
				if(!this.toInMillis) {
					return this.endOfDay
				}
				return this.toInMillis
			},
			set(value) {
				this.$emit('endDateInMillis', value)
			}
		}
	},
	mounted() {
		this.initialStart = this.startOfDay
		this.initialEnd = this.endOfDay
	}
}
</script>

<style scoped>

</style>